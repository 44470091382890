import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "@reach/router";
import App from './app';

declare global {
    interface Window {
        coords: object;
    }
};

window.addEventListener("mousemove", async (e)=>{
    window.coords = { x: e.pageX, y: e.pageY };
});

ReactDOM.render(
    <React.StrictMode>
        <Router style={{ width: "100%", height: "100%" }}>
            <App default={true} />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);