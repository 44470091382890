/**************************************************
* Imports
**************************************************/

import React from 'react';
import { RouteComponentProps } from "@reach/router";

/**************************************************
* Render Component
**************************************************/

export const RenderComponent: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {

    /* Render View */
    return (
        <div className="page-container">

            {/* More */}
            <div className="item">
                <div className="head">
                    <div className="icon"><i className="fa fa-info-circle"></i></div>
                    <div className="label">About The Project</div>
                </div>
                <div className="body">
                    <span><b>The Mourning Sun</b> is the first volume of <b>Earthless</b>, an original Sci-Fi Fantasy novel currently a <b>work in progress</b>, and previously known as its project name: <i>In The Year 2525</i>.</span>
                    
                    <span><b>Earthless</b> has been a very long slow-rolling project originally inspired by classic 16-Bit Sega Genesis shooters such as Gaiares, Arrow Flash, and Thunderforce which always provided a great sense of adventure; as well as classic animes such as Akira, Project A-Ko and Sailor Moon.</span>

                    <span>When it comes to story and world building, bits of inspiration was drawn from Sci-Fi and Fantasy classics such as Alien, The Twilight Zone, The NeverEnding Story, and Gene Roddenberry's Star Trek — including some of the newer suspense-thrillers such as Signs, The Fourth Kind, and Dark Skies.</span>
                    
                    <span>From the moment <b>Irene Jameson</b> wakes up to a world having changed over night, to the moment she reaches the boundaries of the known Universe, the <b>Earthless</b> series will provide fun, adventure, horror and thrills.</span>
                    
                    <span>Thank you for visiting <b>Earthless: The Mourning Sun</b>!</span>
                </div>
            </div>

            {/* Status */}
            <div className="item">
                <div className="head">
                    <div className="icon"><i className="fa fa-tools"></i></div>
                    <div className="label">Status</div>
                </div>
                <div className="body">
                    <div className="status">
                        <div className="link">
                            <div className="icon"><i className="fa fa-feather-alt"></i></div>
                            <div className="label"><b>Source Material</b>: <span className="in-progress">In Progress.</span></div>
                        </div>
                        <div className="link">
                            <div className="icon"><i className="fa fa-book"></i></div>
                            <div className="label"><b>Novel</b>: <span className="in-progress">In Progress.</span></div>
                        </div>
                        <div className="link">
                            <div className="icon"><i className="fa fa-file-image"></i></div>
                            <div className="label"><b>Graphic Novel</b>: <span className="in-planning">In Planning.</span></div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Links */}
            <div className="item">
                <div className="head">
                    <div className="icon"><i className="fa fa-link"></i></div>
                    <div className="label">Links</div>
                </div>
                <div className="body">
                    <div className="grid">
                        <div className="link">
                            <div className="icon"><i className="fab fa-deviantart"></i></div>
                            <div className="label"><a href="http://shadow-corp.com/" target="new">Earthless Renders on Deviant Art</a></div>
                        </div>
                        <div className="link">
                            <div className="icon"><i className="fab fa-facebook"></i></div>
                            <div className="label"><a href="https://www.facebook.com/groups/BNPRandFreestyle" target="new">Blender NPR & Stylized Rendering</a></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default RenderComponent;
