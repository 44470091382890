/**************************************************
* Imports
**************************************************/

import React from 'react';
import { RouteComponentProps } from "@reach/router";

/**************************************************
* Render Component
**************************************************/

export const RenderComponent: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {

    /* Render View */
    return (
        <div className="page-container">

            {/* About The Project */}
            <div className="item">
                <div className="head">
                    <div className="icon"><i className="fa fa-globe-americas"></i></div>
                    <div className="label">About The World</div>
                </div>
                <div className="body">
                    <span>On the morning of June 18th, 2523, a phenomena known as a <b>Solar Pulse</b> wrecks havoc across the Solar System. On Earth, autonomous A.I. systems — which maintain all sectors of society including security, financial, and traffic control systems — begin to fail, putting people on edge as this technology dependent society experiences network outages, blackouts, earthquakes, and traffic accidents for the first time in a century.</span>
                    
                    <span>Emerging from the chaos comes a familiar voice, <b>Mary Camping</b> — the infamous street preacher wanted for terrorism in several nations — gains credibility with the people for prophesying the day the Solar Pulse would occur, claiming the pulse would be a message from God; and using her rise in support for her campaign against <b>Space Corp</b> — vowing to expose them for their “transgressions against God.”</span>
                    
                    <span>Meanwhile, the world is on the brink of war with tensions rising between the two largest superpowers — the <b>Allied Armed Nations (ARM)</b> and the <b>Coalition of Red States (CORE)</b> — with the Coalition making aggressive military moves on and off world, accusing ARM and Space Corp of weaponizing the Sun after the Solar Pulse had destroyed a high value military station at Mercury — an “unprovoked attack” the Coalition considers an act of war.</span>
                    
                    <span>The largest fleet in the Solar System, the <b>United States Space Corp</b>, finds itself in a political battle at home and abroad as classified documents are regularly leaked on the <i>EsperNet</i> revealing egregious scandals and deep internal conflicts damaging to the Corp's credibility; leaks confirming several of the rumors and conspiracy theories that have circulated about the organization and its secretive activities.</span>
                    
                    <span>With the world on the brink of war and society inching closer to total anarchy, the once bright future suddenly takes a dark turn.</span>
                </div>
                <div></div>
            </div>
            
        </div>
    );
}

export default RenderComponent;
