/**************************************************
* Imports
**************************************************/

import React from 'react';
import { Router, Link, RouteComponentProps, useLocation } from "@reach/router";
import logo from '@core/assets/earthless-logo.png';
import './index.scss';

import PageWorld from './page-world';
import PageStory from './page-story';
import PageAbout from './page-about';

/**************************************************
* Render Component
**************************************************/

export const RenderComponent = (props: RouteComponentProps) => {
  const { pathname } = useLocation();

    /* Menu Items */
    const menu = () => [
        { path: "/", label: "World" },
        { path: "/story", label: "Story" },
        { path: "/project", label: "Project" },
    ];
    
    /* isActive Links */
    const isActive = ({ isCurrent }: any) => isCurrent ? { className: "link link-active" } : { className: "link link-inactive" };

    /* Not Found Page */
    const NotFound = (props: RouteComponentProps) => <p style={{ textAlign: "center" }}>Black Hole of 404.</p>;
    
    /* Render View */
    return (
        <div className="earthless">
            
            {/* Links */}
            <div className="head">
                <div className="container">
                    <Link className="logo" to="/"><img src={logo} alt="Earthless Logo" /></Link>
                    <div className="links">
                        {menu().map((item: any, i: number)=><Link key={i} to={item.path} getProps={isActive}>{item.label}</Link>)}
                    </div>
                </div>
            </div>

            {/* Pages */}
            <Router key={pathname} className="body">
                <NotFound default={true} />
                <PageWorld path="/" />
                <PageStory path="/story" />
                <PageAbout path="/project" />
            </Router>
            
        </div>
    );
};

export default RenderComponent;
