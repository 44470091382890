/**************************************************
* Imports
**************************************************/

import React from 'react';
import { RouteComponentProps } from "@reach/router";

/**************************************************
* Render Component
**************************************************/

export const RenderComponent: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {

    /* Render View */
    return (
        <div className="page-container">

            {/* About The Story */}
            <div className="item">
                <div className="head">
                    <div className="icon"><i className="fa fa-book"></i></div>
                    <div className="label">About The Story</div>
                </div>
                <div className="body">
                    <span><b>Irene Jameson</b> has waited all twenty-three grueling years of her life for this very important day: The day she officially becomes a cadet at the prestigious <b>Space Corp Academy</b>, headquartered at the newest wonder of the world, the jewel of the sky, <b>The City of Earth</b>.</span>

                    <span>As the world panics over rumors of war and the Sun having a tantrum, Irene spends her last day on Earth focused on running errands with her kid sister, <b>Charlene Jameson</b>, before leaving for the <b>City of Earth</b>.</span>

                    <span>Over the course of this strange and chaotic day, Irene encounters many odd occurrences of an impossible identity mismatch with bioscanners — a commonly used system to authenticate citizens through the United States Civil Database — identifying Irene as her late mother <b>Miranda Jameson</b>.</span>
                    
                    <span>The frequent bioscan mismatches leads Irene to a <b>locker</b> at an old storage facility where she discovers it belonged to her mother, a locker still actively registered a decade after her mother's death.</span>

                    <span>With Irene's ability to pass as her mother, she gains access to the locker and its contents, not yet realizing she found the first pieces of a mystery that will ultimately lead her to the discovery of the <b><i>Eden Protocol</i></b>, something she comes across several times when using her mother’s credentials to access classified Space Corp networks and encrypted documents, at the risk of being expelled from the Academy.</span>

                    <span>It was Irene’s childhood dream to follow in the footsteps of her parents by joining <b>Space Corp</b>, having grown up reading about their adventures in space, and their many accomplishments in modernizing the Corp. Although she remembers her parents being held as heroes, she learns more about their time in Space Corp after discovering information revealing another side of their story, a story that shatters her entire world.</span>
                </div>
                <div></div>
            </div>
            
        </div>
    );
}

export default RenderComponent;
